import React from "react";
import {Font, Document, Image, Page, PDFDownloadLink, StyleSheet, Text, View,} from "@react-pdf/renderer";
import ViewPDF from "../ViewPDF";
import {Button} from "@material-ui/core";
import NOAPHeader from "../static/img/NOAPHeader.png";
import NOAPFooter from "../static/img/NOAPFooter.png";
import NOAPFooterNew from "../static/img/NOAPFooterNew.png";
import eSig from "../static/img/eSigg.png";

Font.register({
   family: "Times-Italic",
   src: "https://cdnjs.cloudflare.com/ajax/libs/pdfkit/0.8.3/fonts/Times-Italic.ttf",
 });

// Create styles
const styles = StyleSheet.create({
   page: {
      padding: 0,
      height: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: 12,
   },
   body: {
      paddingHorizontal: 70,
   },
   footer: {},
   borderBottom: {
      borderBottomColor: "black",
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
   },
   text: {
     fontFamily: 'Times-Italic', // Built-in font
     fontSize: 9,
     fontStyle: 'italic',
     marginBottom: 15,
     textAlign: 'justify',
   },
});

// Create Document Component
export default function NOAP({data}) {
   return (
      <Document
         title={"Notice of Admission to the Program"}
         author={"University of Southeastern Philippines"}
      >
         <Page size="A4" style={styles.page}>
            <View>
               <Image src={NOAPHeader}/>
               <View style={styles.body}>
                  <View style={{width: 110, marginBottom: 18, marginTop: 15, fontSize:10}}>
                     <View style={styles.borderBottom}>
                        <Text style={{textAlign: "center"}}>
                           {data.date}
                        </Text>
                     </View>
                     <Text style={{textAlign: "center"}}>(Date)</Text>
                  </View>
                  <View style={{marginBottom: 18, fontSize:10}}>
                     <Text style={{textDecoration: 'underline', fontWeight: 'bold'}}>Mr./Ms. {data.name}</Text>
                  </View>
                  <View style={{marginBottom: 10, fontSize:10}}>
                     <Text style={{marginBottom: 8}}>Sir/Madam:</Text>
                     <Text style={{textIndent: 40, marginBottom: 8, textAlign: 'justify'}}>
                        Please take notice that after due evaluation, you are found to qualify, and is now eligible, for
                        enrolment to the program specified hereunder:
                     </Text>
                     <View style={{paddingHorizontal: 40}}>
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                           <View style={{width: '60%'}}>
                              <Text>
                                 College: <Text style={{textDecoration: 'underline'}}>{data.college}</Text>
                              </Text>
                           </View>
                           <View style={{width: '40%'}}>
                              <Text>
                                 Campus: <Text style={{textDecoration: 'underline'}}>{data.campus}</Text>
                              </Text>
                           </View>
                        </View>
                        <Text>
                           Approved Program/Course to Enroll: <Text style={{textDecoration: 'underline'}}>
                           {data.program}
                        </Text>
                        </Text>
                        <Text>
                           Year Level: <Text style={{textDecoration: 'underline'}}>
                           {data.yearLevel}
                        </Text>
                        </Text>
                        <View style={{display: 'flex', flexDirection: 'row'}}>
                           <View style={{width: '50%'}}>
                              <Text>
                                 Semester: <Text style={{textDecoration: 'underline'}}>{data.semester}</Text>
                              </Text>
                           </View>
                           <View style={{width: '50%'}}>
                              <Text>
                                 Academic Year: <Text style={{textDecoration: 'underline'}}>{data.academicYear}</Text>
                              </Text>
                           </View>
                        </View>
                     </View>
                     
                     <Text style={{marginBottom: 5 , marginTop: 20, fontWeight: 'bold'}}>STUDENT’S PLEDGE:</Text>
                     <Text style={styles.text}>
                        In consideration of my admission to the University of Southeastern Philippines and in recognition of
                        the privileges afforded to me as a student of this esteemed institution, I hereby solemnly pledge to
                        adhere to and comply with all rules, regulations, policies, and guidelines established by the competent
                        authorities within the University and the College or School in which I am enrolled, particularly those
                        concerning the prescribed uniform and dress code. Additionally, students are expected to exercise
                        responsibility and prudence in the use of social media. I acknowledge that, by signing this document, I
                        hereby consent to granting my parents access to my grades and student records, upon their request.
                     </Text>
                     
                     <Text style={styles.text}>
                        Additionally, as a recipient of the Free Higher Education grant, I further commit to be actively
                        contributing to the development and improvement of my academic program, my College, and the
                        University of Southeastern Philippines as a whole, in any capacity that aligns with my abilities and
                        responsibilities.
                     </Text>
                  </View>
                  
                  <View style={{display: 'flex', flexDirection: 'row', marginBottom: 10, marginTop: 10, textAlign: 'center'}}>
                     <View style={{width: '50%'}}>
                        <Text style={{textDecoration: 'underline'}}>
                           {data.name ? data.name : 'None'} 
                        </Text>
                        <Text style={{fontWeight: 'bold', fontSize:10}}>Student's Signature</Text>
                        <Text style={{marginBottom: 15, fontSize: 10}}>Signature Over Printed Name</Text>
                        <Text style={{textDecoration: 'underline', minWidth: 200}}>
                           {/* {"                                 "}            */}
                           {data.applicantTelNo ? data.applicantTelNo : 'None'} 
                        </Text>
                        <Text style={{marginBottom: 8, fontSize: 10}}>Contact Number</Text>
                     </View>
                     <View style={{width: '50%'}}>
                        <Text style={{textDecoration: 'underline'}}>
                           {data.applicantEmergency_Contact ? data.applicantEmergency_Contact : 'None'} 
                        </Text>
                        <Text style={{fontWeight: 'bold', fontSize: 10}}>Parent/Guardian</Text>
                        <Text style={{marginBottom: 15, fontSize: 10}}>Signature Over Printed Name</Text>
                        <Text style={{textDecoration: 'underline'}}>
                           {data.applicantEmergency_TelNo ? data.applicantEmergency_TelNo : 'None'} 
                        </Text>
                        <Text style={{marginBottom: 8, fontSize: 10}}>Contact Number</Text>
                     </View>
                  </View>
                  
                  <View style={{marginBottom: 10}}>
                     <Image src={eSig} style={{width: 80, height: 50, margin: 'auto', top: 8}}/>
                     <Text style={{textAlign: 'center'}}>FEDELIS C. BONOCAN, Ph.D.</Text>
                     <Text style={{textAlign: 'center'}}>Director</Text>
                  </View>
               </View>
            </View>
            <Image src={NOAPFooterNew} style={styles.footer}/>
         </Page>
      </Document>
   );
}

export function ViewNOAP({data}) {
   return <ViewPDF documentComponent={<NOAP data={data}/>}/>;
}

export function DownloadNOAP({fileName, data}) {
   return (
      <PDFDownloadLink document={<NOAP data={data}/>} fileName={fileName || "NOAP.pdf"}>
         {({blob, url, loading, error}) => {
            // console.log({blob, url, loading, error});

            // if (url) {
            //   const link = document.createElement("a");
            //   link.download = fileName;
            //   link.href = url;
            //   document.body.appendChild(link);
            //   link.click();
            //   document.body.removeChild(link);
            //   // if (isFunction(afterDownloaded)) afterDownloaded();
            // }

            return (
                  <Button 
                        variant="contained" align="center" color="primary" 
                        style={{margin:"15px auto",borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} >
                           Download NoAP
                  </Button> 
               )

            // <Button variant="outlined" color="primary">Download NoAP</Button>;
         }}
      </PDFDownloadLink>
   );
}
