
import axios from 'axios';

export const API_URL = `https://admissionapi-dev.usep.edu.ph`;
// export const API_URL = `https://admissionapi.usep.edu.ph`;
// export const API_URL = `https://kmssystem.usep.edu.ph`;

// export const CURR_TERMID = 2024;    
// export const CURR_TERMID = 204;    

// export const CURR_TERMID = 204;    
// export const AY = '2025-2026 1st Semester';   

let AY = null;
let CURR_TERMID = null;

axios.get(`${API_URL}/admission/getTermID`)
    .then(response => {
        if (response.data.length > 0) {
            // AY = response.data[0].TermID; 
            CURR_TERMID = response.data[0].TermID; 
            AY = response.data[0].AcademicYear + ' ' + response.data[0].SchoolTerm; 
            console.log('Term ID:', CURR_TERMID);
            console.log('AY:', AY);
        } else {
            console.error('Not found');
        }
    })
    .catch(error => {
        console.error('Error:', error);
    });

export { AY };
export { CURR_TERMID };
