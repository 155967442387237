import React, { useState } from 'react';
import { 
    Container, 
    Typography,
    Grid, 
    Button,
    Paper,
    TableContainer,
    TableRow,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogContentText,
    DialogActions,
} from '@material-ui/core';

const ConfirmQualifiedProgram = (props) => { 
    
    let [selectBtnClicked, setSelectBtnClicked] = useState(true);
    let [userEnteredPasscode, setUserEnteredPasscode] = useState('');
    let [passCodeIsValid, setPassCodeIsValid] = useState(false);
    let [passCodeHelperText, setPassCodeHelperText] = useState('');
    
    const [contactNoApplicant, setContactNoApplicant] = useState(props.applicantTelNo || "");
    const [contactPerson, setContactPerson] = useState(props.applicantEmergency_Contact || "");
    const [contactEmail, setContactEmail] = useState(props.applicantEmergency_Email || "");
    const [contactNo, setContactNo] = useState(props.applicantEmergency_TelNo || "");

    let [contactNoApplicantHelperText, setcontactNoApplicantHelperText] = useState('');
    let [contactPersonHelperText, setcontactPersonHelperText] = useState('');
    let [contactEmailHelperText, setcontactEmailHelperText] = useState('');
    let [contactNoHelperText, setcontactNoHelperText] = useState('');



    let idForTableRow = 0;

    const handlePassCodeInput = (e) => {    
        setUserEnteredPasscode(e.target.value);
    }

    const handleContactNoApplicantInput = (e) => {    
        setContactNoApplicant(e.target.value);   
    }

    const handleContactPersonInput = (e) => {    
        setContactPerson(e.target.value);   
    }
    const handleContactEmailInput = (e) => {    
        setContactEmail(e.target.value);   
    }
    const handleContactNoInput = (e) => {    
        setContactNo(e.target.value);   
    }

    const handlecontactNoApplicantBlur = (e) => {
        if (!e.target.value.trim()) {
            setcontactNoApplicantHelperText("This field is required");
        } else {
            setcontactNoApplicantHelperText('');
        }
    };
    const handlecontactPersonBlur = (e) => {
        if (!e.target.value.trim()) {
            setcontactPersonHelperText("This field is required");
        } else {
            setcontactPersonHelperText('');
        }
    };
    const handlecontactEmailBlur = (e) => {
        if (!e.target.value.trim()) {
            setcontactEmailHelperText("This field is required");
        } else {
            setcontactEmailHelperText('');
        }
    };
    const handlecontactNoBlur = (e) => {
        if (!e.target.value.trim()) {
            setcontactNoHelperText("This field is required");
        } else {
            setcontactNoHelperText('');
        }
    };
    

    const validateInputs = () => {
        
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^09\d{9}$/;

        if (contactPerson.trim() === '') {
            setcontactPersonHelperText("This field is required");
            return false;
        }

        if (contactEmail.trim() === '') {
            setcontactEmailHelperText("This field is required");
            return false;
        } else if (!emailPattern.test(contactEmail)) {
            setcontactEmailHelperText("Enter a valid email address");
            return false;
        }

        if (contactNo.trim() === '') {
            setcontactNoHelperText("This field is required");
            return false;
        } else if (!phonePattern.test(contactNo)) {
            setcontactNoHelperText("Enter a valid 11-digit number starting with 09");
            return false;
        }

        if (contactNoApplicant.trim() === '') {
            setcontactNoApplicantHelperText("This field is required");
            return false;
        } else if (!phonePattern.test(contactNoApplicant)) {
            setcontactNoApplicantHelperText("Enter a valid 11-digit number starting with 09");
            return false;
        }

        if (userEnteredPasscode.trim() === '') {
            setPassCodeHelperText("This field is required");
            return false;
        }

        return true;
    };

    

    const handleCancelConfirmDialog = () => {
        setSelectBtnClicked(false);
        setPassCodeIsValid(false);
        setUserEnteredPasscode('');
        setPassCodeHelperText('');
        props.handleCancelConfirmDialog();
    }

    const handleConfirmBtnClick = () => {
        if(userEnteredPasscode === props.passCode){
            setPassCodeIsValid(true);
            setPassCodeHelperText('');

            if (validateInputs()){
                props.handleConfirmQualifiedProgramClick(contactNoApplicant,contactPerson,contactEmail,contactNo);
                setSelectBtnClicked(false);
                alert('Successfully Enlisted');
            }

        }else{
            setPassCodeIsValid(false);
            setPassCodeHelperText('Incorrect passcode.');
        }
    }

    return(
        <Container maxWidth="sm">
            
            {/* Program Selection Confirmation Dialog */}
            <Dialog fullScreen open={selectBtnClicked} >
                <DialogContent>
                    <DialogContentText >
                        <Typography paragraph variant="body1">
                            You are about to confirm enlistment to the academic program: &nbsp;&nbsp;
                            <div style={{fontWeight:'bold'}}>
                                {props.applicantProgram ? props.applicantProgram : null}
                                {(props.applicantMajor ? ` major in ${props.applicantMajor}` : null )}
                            </div>
                            <br/>
                            Please hit the 'Confirm' button to finalize your enlistment to the program.
                            <br/> 
                            <span style={{color:'maroon',fontSize:'0.9rem',fontStyle:'italic'}}>(Warning! This cannot be undone.)</span> 
                        </Typography>
                    </DialogContentText>
                    
                    <Typography paragraph variant="body1">
                        <div style={{fontWeight:'bold', marginTop: 20}}>
                            Applicant's Details:
                        </div>
                    </Typography>
                    <TextField style={{marginBottom: 10}}
                        id="passCode"
                        name="passCode"
                        label="Pass Code"
                        onBlur={handlePassCodeInput}
                        // onChange={handlePassCodeInput}
                        fullWidth
                        variant="outlined"
                        required
                        error={!passCodeIsValid}
                        helperText={passCodeHelperText}    
                    />
                    <TextField style={{marginBottom: 10}}
                        id="contactNoApplicant"
                        name="contactNoApplicant"
                        label="Applicant's Contact No"
                        onBlur={handlecontactNoApplicantBlur}  
                        onChange={handleContactNoApplicantInput}  
                        fullWidth
                        variant="outlined"
                        required
                        value={contactNoApplicant}  
                        helperText={contactNoApplicantHelperText}  
                        error={contactNoApplicantHelperText}  
                    />
                    <Typography paragraph variant="body1">
                        <div style={{fontWeight:'bold', marginTop: 20}}>
                            Parent/Guardian:
                        </div>
                    </Typography>
                    <TextField style={{marginBottom: 10}}
                        id="contactPerson"
                        name="contactPerson"
                        label="Contact Person"
                        onBlur={handlecontactPersonBlur}  
                        onChange={handleContactPersonInput}  
                        fullWidth
                        variant="outlined"
                        required
                        value={contactPerson}  
                        helperText={contactPersonHelperText}  
                        error={contactPersonHelperText}  
                    />
                    <TextField style={{marginBottom: 10}}
                        id="contactEmail"
                        name="contactEmail"
                        label="Contact Email"
                        onBlur={handlecontactEmailBlur}  
                        onChange={handleContactEmailInput}  
                        fullWidth
                        variant="outlined"
                        required
                        value={contactEmail}  
                        helperText={contactEmailHelperText}  
                        error={contactEmailHelperText}  
                    />
                    <TextField style={{marginBottom: 10}}
                        id="contactNo"
                        name="contactNo"
                        label="Contact No"
                        onBlur={handlecontactNoBlur}  
                        onChange={handleContactNoInput}  
                        fullWidth
                        variant="outlined"
                        required
                        value={contactNo}  
                        helperText={contactNoHelperText}  
                        error={contactNoHelperText}  
                    />
                    <Button autoFocus onClick={handleCancelConfirmDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmBtnClick} color="primary">
                        Confirm
                    </Button>
                </DialogContent>
            </Dialog>

        </Container>
    )

}

export default ConfirmQualifiedProgram;
